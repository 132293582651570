* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'PT Sans', sans-serif;
  }

  hr {
    height: 5px;
    background-color: purple;
    width: 80%;
    margin: auto;
  }

  .clean-link {
    text-decoration: none;
    color: white;
  }
  
  .home,
  .about,
  .members,
  .values,
  .operations,
  .activities,
  .costs,
  .contact-us,
  .schedule-a-visit,
  .apply-today,
  .donate,
  .alumni-database,
  .secret-page {
    display: flex;
    height: 90vh;
    align-items: center;
    justify-content: center;
    font-size: 3rem;
  }
 
  .success {
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    font-size: xx-large;
    padding-top: 50px;
  }

  .about {
    background-image: url('/public/images/about.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    color: #fff;
    font-size: 100px;
  }

  .about-info {
    padding-top: 70px;
    padding-left: 15%;
    padding-right: 15%;
    padding-bottom: 50px;
    line-height: 150%;
    text-align: left;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  }

  .about-image{
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 20%;
    height: 20%;
  }

  .values {
    background-image: url('/public/images/values.jpg');
    background-position-x: 50%;
    background-position-y: 25%;
    background-size: cover;
    background-repeat: no-repeat;
    color: #fff;
    font-size: 100px;
  }

  .values-header,
  .operations-header,
  .activities-header,
  .costs-header {
    padding-top: 50px;
    padding-bottom: 15px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  }

  .values-info,
  .operations-info,
  .activities-info,
  .costs-info {
    padding-left: 15%;
    padding-right: 15%;
    padding-bottom: 10px;
    line-height: 150%;
    text-align: left;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  }

  .values-image,
  .operations-image,
  .activities-image{
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 30px;
    margin-top: 40px;
    width: 40%;
    height: 40%;
    border-radius: 3%;
  }

  .operations-image-special,
  .activities-image-special {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 30px;
    margin-top: 40px;
    width: 30%;
    height: 30%;
    border-radius: 3%;
  }

  .operations {
    background-image: url('/public/images/operations.jpg');
    background-position-x: 50%;
    background-position-y: 50%;
    background-size: cover;
    background-repeat: no-repeat;
    color: #fff;
    font-size: 100px;
  }

  .activities {
    background-image: url('/public/images/activities.JPG');
    background-position-x: 50%;
    background-position-y: 10%;
    background-size: cover;
    background-repeat: no-repeat;
    color: #fff;
    font-size: 100px;
  }

  .costs {
    background-image: url('/public/images/costs.JPG');
    background-position-x: 50%;
    background-position-y: 25%;
    background-size: cover;
    background-repeat: no-repeat;
    color: #fff;
    font-size: 100px;
  }

  .costs-image {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 30px;
    margin-top: 40px;
    width: 30%;
    height: 30%;
  }
    
  .members {
    background-image: url('/public/images/members.jpeg');
    background-position-x: 50%;
    background-position-y: 25%;
    background-size: cover;
    background-repeat: no-repeat;
    color: #fff;
    font-size: 100px;
  }

  .contact-us-container,
  .members-container {
    position: relative;
    text-align: center;
    color: white;
    padding-top: 30px;
  }

  .centered-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: xx-large;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    line-height: 70px;
    background-color: black;
    padding: 30px;
    opacity: 0;
    visibility: hidden;
    transition: visibility 0s, opacity 0.5s linear;
    -webkit-transition: visibility 0s, opacity 0.5s linear;
  }

  .centered-text-special {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: x-large;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    line-height: 60px;
    background-color: black;
    padding: 25px;
    opacity: 0;
    visibility: hidden;
    transition: visibility 0s, opacity 0.5s linear;
    -webkit-transition: visibility 0s, opacity 0.5s linear;
  }

  .centered-text-members,
  .centered-text-contact-us {
    position: absolute;
    top: 53%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    background-color: black;
    padding-bottom: 30px;
    padding-top: 20px;
    padding-left: 30px;
    padding-right: 30px;
    opacity: 0;
    visibility: hidden;
    transition: visibility 0s, opacity 0.5s linear;
    -webkit-transition: visibility 0s, opacity 0.5s linear;
  }

  .members-line {
    height: 1px;
    width: 100%;
    background-color: beige;
  }

  .centered-text-members > h2,
  .centered-text-contact-us > h2 {
    font-size: xx-large;
  }

  .centered-text-members > h3,
  .centered-text-contact-us > h3 {
    font-size: medium;
    line-height: 40px;
  }

  .members-container:hover > div, 
  .contact-us-container:hover > div {
    visibility: visible;
    opacity: 0.7; 
  }

  .members-container:hover > img, 
  .contact-us-container:hover > img {
    opacity: 0.5;
  }

  .contact-us {
    background-image: url('/public/images/contact-us.JPG');
    background-position-x: 50%;
    background-position-y: 50%;
    background-size: cover;
    background-repeat: no-repeat;
    color: #fff;
    font-size: 100px;
  }

  .contact-us-info {
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 20px;
    padding-bottom: 10px;
    line-height: 150%;
    text-align: center;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  }

  .contact-us-info2 {
    padding-left: 15%;
    padding-right: 15%;
    padding-top: 20px;
    padding-bottom: 30px;
    line-height: 150%;
    font-size: x-large;
    text-align: center;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  }

  .contact-us-info3 {
    padding-left: 15%;
    padding-right: 15%;
    padding-top: 20px;
    line-height: 150%;
    text-align: center;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  }

  .contact-us-image-wrapper,
  .members-image-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 50px;;
    gap: 50px;
    position: relative;
    text-align: center;
  }

  @media screen and (max-width: 1024px) {
    .contact-us-image-wrapper,
    .members-image-wrapper {
      flex-direction: column;
    }
  }

 .contact-us-image,
 .members-image {
    border: #fff;
    border-radius: 20px;
    width: 80%;
    height: 80%;
    transition: .5s ease;
    opacity: 1;
    backface-visibility: hidden;
  }

  .schedule-a-visit {
    background-image: url('/public/images/visit.JPG');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    color: #fff;
    font-size: 100px;
  }

  .visit-info {
    padding-left: 15%;
    padding-right: 15%;
    padding-top: 20px;
    padding-bottom: 10px;
    line-height: 150%;
    text-align: center;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  }

  .visit-combine {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .visit-input-container,
  .apply-input-container,
  .members-input-container,
  .donate-input-container,
  .secret-page-input-container{
    padding-top: 20px;
    padding-bottom: 30px;
    gap: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 400px;
    margin: auto;
    height: auto;
  }

  .members-search-container {
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: center;
    align-items: center;
    padding-top: 40px;
    padding-bottom: 50px;
  }

  .members-composite-picture {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 70%;
    padding-top: 50px;
    padding-bottom: 30px;
  }

  .members-header {
    padding-top: 30px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    font-size: xx-large;
  }

  .members-header2 {
    text-align: center;
    color: black;
    padding-top: 50px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    font-size: 200%;
  }

  .members-button {
    display: flex;
    justify-content: center;
  }
  
  .members-school-year-dropdown {
    display: flex;
    justify-content: center;
    padding-top: 30px;
  }

  .alumni-database-button {
    display: flex;
    justify-content: center;
    padding-bottom: 30px;
  }

  .members-add-button {
    display: flex;
    justify-content: right;
    padding-bottom: 30px;
    padding-right: 20px;
  }

  .apply-input-container-2 {
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 400px;
    margin: auto;
    height: auto;
  }

  .text-center {
    padding-top: 20px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  }

  .apply-today {
    background-image: url('/public/images/apply-today.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    color: #fff;
    font-size: 100px;
  }

  .apply-info {
    padding-left: 15%;
    padding-right: 15%;
    padding-top: 20px;
    padding-bottom: 10px;
    line-height: 150%;
    text-align: center;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  }

  .apply-info-2 {
    padding-left: 29%;
    padding-right: 29%;
    padding-top: 40px;
    line-height: 150%;
    text-align: left;
    font-size: medium;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  }

  .apply-info-3 {
    padding-left: 29%;
    padding-right: 29%;
    padding-top: 10px;
    line-height: 150%;
    text-align: left;
    font-weight: lighter;
    font-size: medium;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  }

  .loading-screen {
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    font-size: large;
    text-align: center;
    padding: 50px;
  }

  .centered-datagrid {
    margin-left: 10%;
  }

  .fa-back-button {
    padding-right: 10px;
  }

  .donate {
    background-image: url('/public/images/donate.jpg');
    background-position-x: 60%;
    background-position-y: 45%;
    background-size: cover;
    background-repeat: no-repeat;
    color: #fff;
    font-size: 100px;
  }

  .donate-info {
    padding-top: 70px;
    padding-left: 15%;
    padding-right: 15%;
    line-height: 150%;
    text-align: center;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  }

  .fa-lock {
    padding-right: 10px;
  }

  .secret-page {
    background-image: url('/public/images/secret-page.jpg');
    background-position-x: 50%;
    background-position-y: 60%;
    background-size: cover;
    background-repeat: no-repeat;
    color: #fff;
    font-size: 100px;
  }

  .secret-page-buttons {
    display: flex;
    justify-content: center;
    padding-top: 30px;
    padding-bottom: 30px;
    gap: 50px;
  }